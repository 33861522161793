import React, { useEffect, useState } from 'react'
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination
} from 'react-table'
import { useStaticQuery, graphql } from 'gatsby'
import {
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronDoubleLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from '@heroicons/react/outline'

const SenderFacet = () => {
  const {
    allSenders: { nodes: senders }
  } = useStaticQuery(graphql`
    query SendersQuery {
      allSenders {
        nodes {
          id
          name
          party_affiliation
          office_sought
          source
          total_emails
          type
        }
      }
    }
  `)

  const [senderData, setSenderData] = useState([])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Party Affiliation',
        accessor: 'party_affiliation'
      },
      {
        Header: 'Office Sought',
        accessor: 'office_sought'
      },
      {
        Header: 'Type',
        accessor: 'source'
      },
      {
        Header: 'Volume',
        accessor: 'total_emails'
      }
    ],
    []
  )

  useEffect(() => {
    const filtered = senders.filter((sender) => sender.total_emails > 0)
    const sorted = filtered.sort((a, b) => b.total_emails - a.total_emails)

    // sorted.map(
    //   (sender) =>
    //     (sender.source =
    //       sender.source === 'orgs' ? sender.type : 'Candidate')
    // )

    setSenderData(sorted)
  }, [senders])

  const tableInstance = useTable(
    {
      columns,
      data: senderData,
      initialState: {
        sortBy: [
          {
            id: 'total_emails',
            desc: true
          }
        ]
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize }
  } = tableInstance

  return (
    // apply the table props
    <section className='h-full py-0'>
      <div className='container mx-auto !py-0 mb-8'>
        <div className='!py-0 mb-8'>
          <div className='flex flex-col items-center w-full md:flex-row'>
            <div className='md:w-4/12 lg:w-2/12'>
              <p className='label'>
                <span className=''> Filter Senders</span>
              </p>
            </div>
            <div className='w-full md:w-8/12 lg:w-9/12'>
              <input
                className='w-full'
                name='allSearch'
                onChange={(e) => {
                  setGlobalFilter(e.target.value)
                }}
              />
            </div>
          </div>
        </div>
        <table className='w-full my-5 overflow-y-scroll' {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr
                  className='table-row mb-2 border-b-2 border-gray-200 sm:mb-0'
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className='w-1/4 gap-1 text-left last:text-right last:inline-flex'
                      >
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        <span className='inline'>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ChevronDownIcon className='inline w-4 h-4' />
                            ) : (
                              <ChevronUpIcon className='inline w-4 h-4' />
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className='even:bg-primary/50'>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className='last:text-right '>
                        {cell.column.id === 'name' ? (
                          <a href={`/entity/${cell.row.original.id}`}>
                            {cell.render('Cell')}
                          </a>
                        ) : cell.column.id === 'total_emails' ? (
                          cell.row.original.total_emails.toLocaleString(
                            'en-US',
                            {
                              maximumFractionDigits: 0
                            }
                          )
                        ) : cell.column.id === 'source' ? (
                          <>
                            {cell.row.original.source === 'orgs'
                              ? cell.row.original.type
                              : 'Candidate'}
                          </>
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className='flex flex-col items-center justify-center gap-6 mt-6 pagination'>
          <div className='flex items-center gap-2'>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <ChevronDoubleLeftIcon className='w-5 h-5 text-gray-400' />
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              <ChevronLeftIcon className='w-5 h-5 text-gray-400' />
            </button>

            <span className='label'>
              Page{' '}
              <span className='text-blue-400'>
                {pageIndex + 1} of {pageOptions.length}
              </span>
            </span>

            <button onClick={() => nextPage()} disabled={!canNextPage}>
              <ChevronRightIcon className='w-5 h-5 text-gray-400' />
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <ChevronDoubleRightIcon className='w-5 h-5 text-gray-400' />
            </button>
          </div>

          <div>
            <span className='label'>
              Go to page:{' '}
              <input
                type='number'
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                style={{ width: '100px' }}
              />
            </span>{' '}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value))
              }}
              className='w-32 label'
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SenderFacet
