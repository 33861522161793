import React from 'react'
import Layout from "../containers/Layout"
import Seo from "../containers/SEO"
import SenderFacet from '../common/search/elastic/facets/SenderFacet'

function Senders() {
  return (
    <Layout>
      <Seo title="Search Campaigns & Organizations" />
      <section className="pt-20 pb-0">
        <div className="container mx-auto !pt-0">
          <h1 className="border-b-4 border-blue-400">Search Campaigns & Organizations</h1>
        </div>
      </section>
      <SenderFacet />
    </Layout>
  )
}

export default Senders
